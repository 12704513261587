import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeading from "../components/pageheading"

const NotFoundPage = ({data}) => (
    <Layout>
        <SEO title="404: Not found" />
        <PageHeading title="Page Not Found" imageData={data.headerImage} />
        <main className="main">
        <div className="typography inner">

            <div className="text-holder">
                <p>
                You just hit a route that doesn&#39;t exist... the sadness. Please use the menu to navigate the pages of this site.
                </p>
            </div>

        </div>
        </main>
    </Layout>
)
export const pageQuery = graphql`
     {
        headerImage: imageSharp(resolutions: {originalName: {eq: "header-crop_43A2025.jpg"}}) { 
            ...ResponsiveImage
        }

    
    } 
`
export default NotFoundPage
